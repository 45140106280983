<template>
  <transition name="fade" appear mode="out-in">
    <header v-if="!stateModal" class="header scrollto" ref="header">
      <div class="header__container header__inner">
        <router-link to="/" class="logo-link">
          <img src="@/assets/img/logo-v2.svg" alt="logo" class="logo" />
        </router-link>
        <nav class="nav">
          <ul>
            <li
              v-scroll-to="{
                el: `#${item.id}`,
                container: 'body',
                duration: 500,
                easing: 'ease',
                // offset: -76,
                force: true,
                cancelable: true,
                onStart: false,
                onDone: false,
                onCancel: false,
                x: false,
                y: true,
              }"
              v-for="(item, i) of menu"
              :key="item.id"
              :class="['nav__item', { active: i == sectionActiveIndex }]"
              @click="onChange(item, i, $event)"
            >
              <a :href="`#${item.id}`">
                {{ item.name }}
              </a>
            </li>
          </ul>
        </nav>
        <div
          @click="isOpen = !isOpen"
          :class="['button-menu', { active: isOpen }]"
        >
          <span class="one" />
          <span class="two" />
          <span class="three" />
        </div>
      </div>
      <BurgerMenu
        v-if="isOpen"
        :open="isOpen"
        :menu="menu"
        @close="isOpen = !isOpen"
      />
    </header>
  </transition>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { GET_HEADER } from "@/store/header/types";
import BurgerMenu from "./BurgerMenu.vue";

export default {
  components: { BurgerMenu },
  name: "Header",
  props: {
    dataPath: {
      type: String,
      default: "izo",
    },
  },
  data() {
    return {
      menu: null,
      isOpen: false,
      sectionActiveIndex: 0,
      offsets: [],
      latestScrollY: 0,
      offsetTop: 0,
      ticking: false,
      disabled: false,
      ids: [],
      fullHeaderMenu: [
        {
          id: "about",
          name: "О музее",
          active: true,
        },
        {
          id: "ticket",
          name: "Билет",
        },
        {
          id: "activation",
          name: "Активации",
        },
        {
          id: "audioguide",
          name: "Аудиогид",
        },
        {
          id: "video",
          name: "Видео",
        },
        {
          id: "events",
          name: "Выставки и мероприятия",
        },
        {
          id: "where",
          name: "Куда сходить",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["stateModal", "museumHeader"]),
    header() {
      return this.fullHeaderMenu.filter((el) => {
        const find = this.ids.find((item) => item == el.id);
        if (find) return find;
      });
    },
  },
  created() {
    this.GET_HEADER(this.dataPath);
  },
  mounted() {
    this.initMenu();
    this.init();
    window.addEventListener("scroll", this.onScroll, false);
    window.addEventListener("resize", this.onResize, false);

    this.$nextTick(() => {
      this.onResize();
    });
  },
  unmounted() {
    window.removeEventListener("scroll", this.onScroll, false);
    window.removeEventListener("resize", this.onResize, false);
  },
  methods: {
    ...mapMutations([GET_HEADER]),
    initMenu() {
      if (this.$route.path !== "/hub") {
        let mainChildren = document.getElementById("main").childNodes;
        this.ids = [];
        for (let i = 0; i < mainChildren.length; i++) {
          if (mainChildren[i].id) {
            this.ids = [...this.ids, mainChildren[i].id];
          }
        }
      }
      this.menu = this.$route.path === "/hub" ? this.museumHeader : this.header;
    },
    init() {
      this.find();
      this.refresh();
      this.process();
    },
    find() {
      this.menu.forEach((e) => {
        const { id } = e;
        const el = document.getElementById(id);

        if (el) {
          e.node = el;
        }
      });
    },
    refresh() {
      this.offsets = [];
      this.menu.forEach((e) => {
        const top = e.node.getBoundingClientRect().top + window.pageYOffset;
        this.offsets.push(Math.floor(top));
      });

      // переключаем раздел на 1/3 экране
      this.offsetTop = window.innerHeight / 3;
    },
    process() {
      const scrollTop = this.latestScrollY + this.offsetTop;

      this.offsets.forEach((offset, index) => {
        const isActiveTarget =
          index !== this.sectionActiveIndex &&
          scrollTop >= offset &&
          (typeof this.offsets[index + 1] === "undefined" ||
            scrollTop < this.offsets[index + 1]);

        if (isActiveTarget) {
          this.sectionActiveIndex = index;
        }
      });

      this.ticking = false;
    },
    requestTick() {
      if (!this.ticking) {
        requestAnimationFrame(this.process);
      }
      this.ticking = true;
    },
    onScroll() {
      this.latestScrollY = window.scrollY; //No IE8
      this.requestTick();
    },
    onResize() {
      this.refresh();
      this.process();
    },
    onChange(section, index, e) {
      e.preventDefault();

      if (this.disabled) {
        return;
      }
      this.disabled = true;
      this.ticking = true;

      this.sectionActiveIndex = index;

      setTimeout(() => {
        this.ticking = false;
        this.disabled = false;
      }, 500);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  height: 80px;
  // border-bottom: 1px solid #d1dbd6;
  position: absolute;
  background: #000000;
  z-index: 1000;
  top: 0;
  width: 100%;
  .logo {
    width: 56px;
    &-link {
      width: fit-content;
    }
  }
  &__container {
    max-width: var(--containerMaxWidht);
    // padding: 0 5px;
    padding: 0 var(--gridGutter);
    margin: 0 auto;
    @include onlyMobile {
      // padding: 0 24px;
    }
  }
  &__inner {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
  }
  .nav {
    height: 100%;
    margin-bottom: -1px;
    ul {
      display: grid;
      grid-auto-flow: column;
      height: 100%;
      // grid-template-columns: repeat(7, [col] 1fr);
      column-gap: 16px;
      // margin-bottom: -1px;
    }
    &__item {
      // padding-bottom: 27px;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #ffff;
      white-space: nowrap;
      transition: all 0.3s;
      &:hover {
        color: #626569;
      }
      &.active {
        // color: #626569;
        > a {
          // border-bottom-color: #626569;
        }
      }
      > a {
        display: flex;
        align-items: center;
        height: 100%;
        border-bottom: 1px solid transparent;
      }
    }
    .active {
      position: relative;
      // color: #626569;
      // &:after {
      //   content: "";
      //   width: 100%;
      //   position: absolute;
      //   border-bottom: 1px solid #1f2229;
      //   left: 0;
      //   bottom: -27px;
      // }
    }
  }
}

.button-menu {
  display: none;
  width: 52px;
  height: 32px;
  padding: 8px 18px;
  // margin-right: -13px;
  justify-content: space-between;
  flex-direction: column;
  cursor: pointer;
  span {
    display: block;
    width: 100%;
    height: 2px;
    background: #8f9399;
    transition: all 0.2s;
  }
  &.active {
    .one {
      transform: rotate(45deg) translate3d(4.7px, 5px, 0);
    }
    .three {
      transform: rotate(-45deg) translate3d(4px, -4px, 0);
    }
    .two {
      display: none;
    }
  }
}

@media (max-width: 1023px) {
  .nav {
    display: none;
  }
  .button-menu {
    display: flex;
    border: 1px solid #26282C;
    border-radius: 8px;
  }
  .header {
    height: 64px;
    &__inner {
      grid-template-columns: 1fr auto;
    }
    .logo {
      width: 40px;
    }
  }
}

@media (max-width: 767px) {
}


</style>
