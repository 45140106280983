<template>
  <div class="event-item">
    <div class="event-item__img">
      <img
        :src="`${urlPath}/${event.previewImageLink}`"
        :alt="event.image"
        width="100%"
      />
    </div>
    <div class="content">
      <div>
        <div v-if="event.onlySubs" class="for">
          <span>Для абонентов <span>t2</span></span>
        </div>
        <h5 class="title">{{ event.title }}</h5>
        <p class="desc">{{ event.description }}</p>
      </div>
      <a :href="hrefLink" class="btn btn-green" @click="openModal">
        Получить бесплатный билет
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
    },
  },
  name: "EventItem",
  data() {
    return {
      isDesktop: window.innerWidth > 767,
    };
  },
  computed: {
    urlPath() {
      return process.env.VUE_APP_BASE_API;
    },
    hrefLink() {
      return this.isDesktop ? this.event.timePadLink : '';
    },
  },
  methods: {
    updateIsDesktop() {
      this.isDesktop = window.innerWidth > 767;
    },
    openModal(event) {
      if (!this.hrefLink) {
        event.preventDefault();
      }

      if (!this.isDesktop) {
        this.$emit("openModal", this.event);
      }
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateIsDesktop);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateIsDesktop);
  },
};
</script>

<style lang="scss" scoped>
.event-item {
  width: 100%;
  @include onlyMobile {
    // width: 200px;
  }
  &__img {
    overflow: hidden;
    width: 100%;
    height: 450px;
    position: relative;
    border: 1px solid rgba(196, 196, 196, 0.5);
    border-radius: 12px;
    display: flex;
    align-items: center;
    @media(max-width: 1160px) {
      height: 36vw;
    }
    @include onlyMobile {
      // width: 200px;
      // height: 179px;
    }
    img {
      @include onlyMobile {
        // width: 200px;
      }
    }
  }
  .title {
    font-family: var(--fontSecondary);
    font-size: 22px;
    font-weight: 800;
    line-height: 20px;
    margin: 0 0 16px 0;
    @include onlyMobile {
      font-size: 22px;
      line-height: 20px;
      margin: 0 0 4px 0;
    }
  }
  .desc {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    color: #1F2229;
    opacity: .7;
    margin: 0 0 16px 0;
    @include onlyMobile {
      font-size: 12px;
      line-height: 15px;
      margin: 0 0 4px 0;
    }
  }
  .content {
    // width: 322px;
    // min-height: 210px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 0 0 0;
    @include onlyMobile {
      padding: 8px 0 0 0;
      // width: 200px;
      // min-height: 200px;
      // padding: 16px 18px 16px 16px;
    }
    .for {
      // width: 164px;
      // height: 24px;
      display: inline-block;
      padding: 2px 8px;
      background-color:#B9F94F;
      border-radius: 400px;
      margin: 0 0 16px 0;
      // background-image: url("../assets/img/icons/for-tele2.svg");
      // background-repeat: no-repeat;
      // position: absolute;
      // z-index: 1;
      // top: 0;
      // left: 0;
      // display: flex;
      // align-items: center;
      @include onlyMobile {
        // width: 118px;
        // height: 18px;
        // background-size: 118px 18px;
        padding: 2px 11px;
        margin: 0 0 8px 0;
      }
      span {
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        color:#000000;
        text-transform: uppercase;
        span {
          text-transform: none;
        }
        // margin-left: 12px;
        @include onlyMobile {
          font-size: 11px;
          line-height: 16px;
          text-align: center;

        }
      }
    }
    .btn {
      margin: 0;
      padding: 1.5rem 4.2rem;
      font-size: 16px;
      font-weight: 800;
      line-height: 20px;
      @include onlyMobile {
        padding: 1.5rem 0.6rem;
        width: 100%;
      }
      @media(max-width: 375px) {
        font-size: 13px;
      }
    }
  }
}
</style>
