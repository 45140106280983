<template>
  <div class="teaser">
    <div class="teaser__body">
      <picture v-if="device === 'desktop'" class="teaser-pic">
      <img :src="`${urlPath}/${pathDesktop}`" width="100%" height="360" />
       <!-- <img src="@/assets/img/teaser-desktop.webp" width="100%" height="360"> -->
      </picture>
      <picture v-else-if="device === 'tablet'" class="teaser-pic">
        <img :src="`${urlPath}/${pathTablet}`" width="100%" height="auto" />
        <!-- <img src="@/assets/img/teaser-desktop.webp" width="100%" height="auto"> -->
      </picture>
      <picture v-else-if="device === 'mobile'" class="teaser-pic">
        <img :src="`${urlPath}/${pathMobile}`" width="auto" height="auto" />
        <!-- <img src="@/assets/img/teaser-mobile.webp" width="auto" height="auto"> -->
      </picture>
      <!-- <picture v-if="device === 'mobile'" class="teaser-text">
        <img :src="`${urlPath}/${pathMobileText}`" width="768" />
      </picture> -->
      <div class="teaser__bage">
        <div class="teaser__heading">На связи <br> с&nbsp;искусством</div>
        <a
          href="#about"
          class="btn btn-green"
          v-scroll-to="{
            el: '#about',
            container: 'body',
            duration: 500,
            easing: 'ease',
            // offset: -76,
            force: true,
            cancelable: true,
            onStart: false,
            onDone: false,
            onCancel: false,
            x: false,
            y: true,
          }"
        >
          Подробнее
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Teaser",
  props: {
    pathDesktop: String,
    pathTablet: String,
    pathMobile: String,
    pathMobileText: String,
    color: String,
  },
  computed: {
    urlPath() {
      return process.env.VUE_APP_BASE_API;
    },
    bgColor() {
      return this.color;
    },
  },
};
</script>

<style lang="scss" scoped>
.teaser {
  padding: 20px 8px 32px;
  background-color: #000;
  display: grid;
  justify-content: center;
  @media(max-width: 1024px) {
    // display: block;
  }
  @include onlyMobile {
    padding: 20px 8px 15px;
  }
  &__body {
    position: relative;
    max-width: var(--containerMaxWidht);
  }
  &__bage {
    position: absolute;
    top: 24px;
    left: 24px;
    padding: 24px;
    border-radius: 24px;
    width: 520px;
    height: 312px;
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media(max-width: 1160px) {
      height: 27vw;
    }
    @media(max-width: 1024px) {
      width: 420px;
      // height: 277px;
      bottom: 18px;
      top: auto;
      left: 18px;
    }
    @include onlyMobile {
      padding: 12px;
      bottom: 8px;
      top: auto;
      left: 8px;
      width: auto;
      height: auto;
      border-radius: 12px;
      // max-width: 344px; 
      width: 95.5%;
    }
    .btn.btn-green {
      margin-bottom: 0;
      width: 100%;
      padding: 1.5rem 9.5rem;
      @include onlyMobile {
        display: none;
      }
    }
  }
  &__heading {
    font-family: var(--fontSecondary);
    color: #fff;
    font-size: 38px;
    font-weight: 800;
    line-height: 34px;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    @include onlyMobile {
      font-size: 22px;
      line-height: 20px;
    }
  }
  &-pic {
    @media (min-width: 1152px) {
      // position: relative;
      // overflow: initial;
      width: auto;
      &::before {
        position: absolute;
        top: 0;
        right: calc(100% - 1px);
        width: 100vw;
        height: 100%;
        background-color: v-bind(bgColor);
        // content: "";
      }
    }
    display: grid;
    justify-content: center;
    overflow: hidden;
    // width: 100vw;
    min-width: 320px;
    border-radius: 32px;
    @include onlyMobile {
      border-radius: 18px;
    }
  }
  &-text {
    position: relative;
    display: block;
    margin-top: -50px;
    overflow: hidden;
  }
  img {
    display: block;
    max-width: 100%;
  }
}
</style>
