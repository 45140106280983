<template>
  <div
    class="activation-item"
    :style="{ alignItems: item.description ? 'flex-start' : 'center' }"
  >
    <img
      v-if="item.imageLink"
      :src="`${urlPath}/${item.imageLink}`"
      :alt="item.imageLink"
    />
    <div v-else />
    <div>
      <h5 class="title">{{ item.text }}</h5>
      <p v-if="item.description" v-html="item.description" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
  name: "ActivationHubItem",
  computed: {
    urlPath() {
      return process.env.VUE_APP_BASE_API;
    },
  },
};
</script>

<style lang="scss" scoped>
.activation-item {
  display: grid;
  grid-template-columns: 64px 1fr;
  column-gap: 16px;
  max-width: 553px;
  width: 100%;
  // align-items: center;
  @include tablet {
    grid-template-columns: 40px 1fr;
  }
  img {
    margin: 0 auto;
    display: block;
    @include tablet {
      height: 40px;
    }
  }
  h5 {
    font-size: 24px;
    line-height: 30px;
    margin: 0px;
    @include tablet {
      font-size: 16px;
      line-height: 23px;
    }
  }
  p {
    font-size: 18px;
    line-height: 140%;
    margin-top: 16px;
    @include tablet {
      font-size: 14px;
    }
  }
  a {
    display: block;
    text-decoration: underline;
    font-size: 18px;
    line-height: 140%;
    font-weight: bold;
    margin-top: 32px;
    @include tablet {
      margin-top: 8px;
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 1152px) {
  .activation-item {
    margin: 0 auto;
  }
}
</style>
