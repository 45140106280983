<template>
  <div
    @mouseenter="active = true"
    @mouseleave="active = false"
    class="video-item"
  >
    <div class="video-item__img" @click="openModal">
      <img
        v-if="video.previewImage"
        :src="`${urlPath}/${video.previewImage}`"
        alt="ded"
        class="preview"
      />
      <button :class="['btn-play', { active: active }]">
        <img src="@/assets/img/play-v2.svg" alt="play" />
      </button>
    </div>
    <h5
      :class="['video-item__title', { active: active }]"
      v-html="video.title"
    />
  </div>
</template>

<script>
export default {
  name: "VideoItem",
  props: {
    video: {
      type: Object,
    },
  },
  data() {
    return {
      active: false,
    };
  },
  computed: {
    urlPath() {
      return process.env.VUE_APP_BASE_API;
    },
  },
  methods: {
    openModal() {
      this.$emit("openModal", this.video);
    },
  },
};
</script>

<style lang="scss" scoped>
.video-item {
  cursor: pointer;
  width: 100%;
  @include tablet {
    // width: 272px;
    margin: 0 auto;
  }
  &__img {
    background: #1f2229;
    border-radius: 12px;
    width: 100%;
    height: 450px;
    overflow: hidden;
    margin-bottom: 16px;
    position: relative;
    @media(max-width: 1160px) {
      height: 36vw;
    }
    @include tablet {
      // width: 272px;
      // height: 170px;
      margin-bottom: 8px;
    }
    @include onlyMobile {
      // height: 179px;
      // height: 45vw;
    }
    .preview {
      height: auto;
      width: 100%;
      // min-width: 322px;
      // min-height: 202px;
      @include tablet {
        // min-width: 272px;
        // min-height: 170px;
      }
    }
  }
  &__title {
    font-family: var(--fontPrimary);
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    transition: all 0.3s;
    @include tablet {
      font-size: 16px;
      line-height: 23px;
    }
    @include onlyMobile {
      font-size: 12px;
      line-height: 16px;
      margin: 0 0 12px 0;
    }
    &.active {
      color: #4c5772;
    }
  }
  .btn-play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
    background: none;
    @include tablet {
      // top: 32%;
      // left: 36%;
    }
    img {
      opacity: 0.7;
      transition: all 0.3s;
      @include tablet {
        width: 48px;
      }
    }
    &.active {
      img {
        opacity: 1;
      }
    }
  }
}
</style>
