<template>
  <div id="video">
    <div class="container">
      <div class="video__body">
        <h1 class="title">Видеоматериалы</h1>
        <!-- TODO: Онлайн-экскурсии пока нет -->
        <!-- <MultiButton :buttons="buttons" :selected="selected" @select="select" /> -->
        <MultiButton :buttons="buttons" :selected="selected" @select="select" activeClass="button--white" />
        <div class="swiper">
          <Swiper
            :pagination="{
              el: '.pag-video',
            }"
            :watchOverflow="true"
            :spaceBetween="10"
            :slidesPerView="isDevice == 'mobile' ? 1 : 1"
            :navigation="{
              nextEl: '.right',
              prevEl: '.left',
            }"
          >
            <SwiperSlide v-for="(el, i) of getVideos" :key="i">
              <VideoItem :video="el" @open-modal="onOpenModal" />
            </SwiperSlide>
          </Swiper>
          <div class="swiper-pagination pag-video" />
        </div>
        <div class="navigation">
          <button class="button left">
            <img src="@/assets/img/icons/left-v2.svg" />
          </button>
          <button class="button right">
            <img src="@/assets/img/icons/right-v2.svg" />
          </button>
        </div>
      </div>
    </div>
  </div>
  <transition name="modal">
    <Modal v-if="modalShow" @close="modalShow = false">
      <template #body>
        <div class="modal-video__content">
          <iframe
            width="544"
            height="305"
            :src="`${getVideoHash}?autoplay=1&loop=1&modestbranding=1&playsinline=1`"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <div class="video"></div>
          <h3 class="title">{{ modalContent.title }}</h3>
          <p class="desc">
            {{ modalContent.description }}
          </p>
        </div>
      </template>
    </Modal>
  </transition>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { CHANGE_STATE } from "@/store/modal/types";
import { GET_VIDEO } from "@/store/video/types";
import MultiButton from "./MultiButton.vue";
import VideoItem from "./VideoItem.vue";

import Modal from "@/components/Modal.vue";

// swiper
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";

SwiperCore.use([Navigation, Pagination]);

export default {
  components: { MultiButton, Swiper, SwiperSlide, VideoItem, Modal },
  name: "Video",
  props: {
    dataPath: {
      type: String,
      default: "izo",
    },
    // buttons: {
    //   type: Array,
    //   default: () => [],
    // },
  },
  data() {
    return {
      selected: "Лекции",
      video: null,
      isDevice: null,

      modalShow: false,
      modalContent: null,
      offset: 0,
    };
  },
  created() {
    this.GET_VIDEO(this.dataPath);
    this.video = this.museumVideo;
    this.selected = this.buttons[0];
    this.calcOffset();
    window.addEventListener("resize", this.onResize);
  },
  mounted() {
    this.isDevice = this.device;
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    ...mapGetters(["museumVideo", "videos"]),
    buttons() {
      if (!this.videos.online_ex.length && this.videos.lectures.length) {
        return ["Лекции"];
      } else if (this.videos.online_ex.length && !this.videos.lectures.length) {
        return ["Онлайн-экскурсии"];
      }
      return ["Лекции", "Онлайн-экскурсии"];
    },
    getVideos() {
      if (this.selected == "Лекции") return this.videos.lectures;
      return this.videos.online_ex;
    },
    getVideoHash() {
      let find = this.modalContent.videoLink.includes("v=");

      function getParameterByName(name, url = window.location.href) {
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
      }

      if (find) {
        let v = getParameterByName("v", this.modalContent.videoLink);
        return `https://www.youtube.com/embed/${v}`;
      }
      return this.modalContent.videoLink;
    },
  },
  methods: {
    ...mapMutations([CHANGE_STATE, GET_VIDEO]),
    onResize() {
      this.calcOffset();
    },
    select(el) {
      this.selected = el;
    },
    onOpenModal(content) {
      this.modalContent = content;
      this.modalShow = true;
      this.CHANGE_STATE(true);
    },
    calcOffset() {
      if (this.isDevice === "mobile") {
        this.offset = 0;
        return;
      }

      const cointainerWidth = document.querySelector(".container").clientWidth;
      const cointainerGutter = 36;
      const windowWidth = window.innerWidth;

      this.offset = (windowWidth - cointainerWidth + cointainerGutter) / 2;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-video {
  max-width: 668px;
  &__content {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 1088px;
    height: 450px;
    padding: 0;
    @media screen and (max-width: 1199px) {
      width: 90vw;
    }
    @include tablet {
      // width: 100%;
      // max-width: 544px;
      // padding: 0 24px;
    }
    @include onlyMobile {
      width: 100%;
      padding: 0 12px;
    }
    iframe {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      @include tablet {
        // width: 100%;
        // height: 200px;
      }
      @include onlyMobile {
        height: 179px;
      }
    }
    .title {
      font-family: var(--fontPrimary);
      margin-top: 24px;
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #000;
      margin-bottom: 16px;
      display: none;
      @include tablet {
        margin-top: 20px;
        margin-bottom: 16px;
        // padding: 0 24px;
        font-size: 16px;
        line-height: 23px;
      }
      @include onlyMobile {
        display: block;
        margin: 8px 0;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;

      }
    }
    .desc {
      font-size: 16px;
      line-height: 140%;
      margin: 0;
      display: none;
      @include tablet {
        font-size: 14px;
        line-height: 140%;
        // padding: 0 24px;
      }
      @include onlyMobile {
        display: block;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
}
.video {
  &__body {
    padding: 24px;
    background-color: var(--colorGray);
    border-radius: 32px;
    @include onlyMobile {
      padding: 12px;
      border-radius: 18px;
    }
  }
  & .swiper > .swiper-container > .swiper-wrapper {
    width: 100vw;
  }
}
#video {
  min-height: 294px;
  // padding-top: 40px;
  position: relative;
  &::before {
    position: absolute;
    top: 100%;
    margin-top: -1px;
    left: 0;
    width: 100%;
    background: #70c9f8;
    // content: "";
    pointer-events: none;
    clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 0% 100%);
    height: 265px;
    top: 0;
    @include desktop {
      clip-path: polygon(0% 0%, 108% 0%, 0% 100%);
      height: 334px;
    }
  }
  @include onlyMobile {
    padding-top: 0;
    padding-bottom: 12px;
  }
  
  .m-btn {
    margin-bottom: 16px;
    background-color: #D9D9D9;
    border-radius: 12px;
    padding: 4px;
    @include onlyMobile {
      width: 100%;
      flex-wrap: nowrap;
      gap: 2px;
    }
  }
  
   .title {
    margin: 0 0 16px 0;
   }

  .swiper-slide {
    width: 100%;
    @include tablet {
      width: 100%;
    }
  }
  
  .swiper-container {
    width: 100%;
    border-radius: 12px;
  }
  .navigation {
    margin-top: 32px;
    margin-right: 7px;
    display: flex;
    justify-content: flex-end;
    @include onlyMobile {
      display: none;
    }
    & .button {
      width: 32px;
      height: 32px;
      padding: 4px;
      background-color: #000;
      border-radius: 8px;
    }
    .left {
      margin-right: 8px;
    }
  }
}
.swiper {
  // width: 100vw; // хак, что бы slidesPerView: auto правильно высчитывал ширину
}

.swiper-pagination {
  display: none;
  @include onlyMobile {
    display: flex;
  }
}
</style>

<style>
  #video > .container .m-btn > .m-btn-item {
    padding: 14px 20px;
    width: 279px;
    border-radius: 12px;
    background-color: transparent;
    color: #000000;
  }

  #video > .container .m-btn > .m-btn-item.button--white {
    background-color: #fff;
  }

  @media (max-width: 767px) {
    #video > .container .m-btn > .m-btn-item {
      padding: 11px;
      width: 100%;
      border-radius: 8px;
    }
  }
  .swiper > .swiper-container > .swiper-wrapper {
    width: 90vw;
  }
</style>
