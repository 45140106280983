<template>
  <transition name="slide-top" appear mode="out-in">
    <div v-if="open" class="burger">
      <nav class="nav">
        <ul>
          <li
            v-scroll-to="{
              el: `#${item.id}`,
              container: 'body',
              duration: 500,
              easing: 'ease',
              offset: -52,
              force: true,
              cancelable: true,
              onStart: false,
              onDone: false,
              onCancel: false,
              x: false,
              y: true,
            }"
            v-for="item of menu"
            :key="item.id"
            :class="['nav__item', { active: item.active }]"
            @click="close"
          >
            <a :href="`#${item.id}`">
              {{ item.name }}
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    menu: {
      type: Array,
    },
  },
  name: "BurgerMenu",
  methods: {
    close() {
      setTimeout(() => {
        this.$emit("close");
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.burger {
  background: white;
  position: fixed;
  top: 64px;
  padding: 8px 12px 0;
  width: 100%;
  min-height: 100vh;
  overflow: auto;

  .nav {
    max-height: 100vh;
    border-radius: 10px;
    box-shadow: 0px 2px 16px 0px #0000000F;
    padding: 16px 12px;
    li {
      margin-bottom: 34px;
      a {
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;

      }
    }
  }
}
</style>
