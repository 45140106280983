<template>
  <div class="modal popup-modal" tabindex="-1">
    <div class="modal-dialog modal-xl modal-dialog-centered">
      <div class="modal-content">
        <button
          @click="close"
          type="button"
          class="modal-close modal-close_type_sticky"
          aria-label="Закрыть"
        ></button>
        <div class="modal-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12 col-md-8 offset-md-2">
                <slot name="head"></slot>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer">
          <slot name="foot"></slot>
        </div>
      </div>
    </div>
    <div @click="close" class="modal-backdrop"></div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { CHANGE_STATE } from "@/store/modal/types";

export default {
  name: "Modal",
  mounted() {
    document.body.style.overflowY = "hidden";
  },
  created() {
    document.addEventListener("keyup", this.onClose);
  },
  unmounted() {
    document.removeEventListener("keyup", this.onClose);
    document.body.style.overflowY = "auto";
  },
  methods: {
    ...mapMutations([CHANGE_STATE]),
    close() {
      this.$emit("close");
      this.CHANGE_STATE(false);
    },
    onClose(event) {
      if (event.keyCode === 27) {
        this.close();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  // pointer-events: none;
  &-close {
    @include reset-button;
    position: absolute;
    width: 52px;
    height: 52px;
    z-index: 10;
    transition: 0.15s;
    top: 0;
    right: -50px;
    // background-color: #fff;
    @media (min-width: 990px) {
      // top: -1px;
      // right: -1px;
    }
    @include onlyMobile {
      right: 0;
    }
    &:hover {
      opacity: 0.7;
    }
    &::before {
      position: absolute;
      width: 24px;
      height: 2px;
      background-color: #fff;
      content: "";
      transform: translate(-50%, -50%) rotate(45deg);
      @include onlyMobile {
        background-color: #1F2229;
      }
    }
    &::after {
      position: absolute;
      height: 24px;
      width: 2px;
      background-color: #fff;
      content: "";
      transform: translate(-50%, -50%) rotate(45deg);
      @include onlyMobile {
        background-color: #1F2229;
      }
    }
  }
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: auto;
  transition: transform 0.3s ease-out;
  z-index: 2000;
  pointer-events: none;
  max-width: 1088px;
  height: 100%;
  @include onlyMobile {
    padding: 12px;
  }
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 30px);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  // background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  pointer-events: all;
  height: 100vh;
  padding: 20px 0;
  padding-top: 60px;
  @media (min-width: 990px) {
    height: auto;
    // border: 1px solid #1f2229;
    padding: 50px 0;
  }
  @media screen and (max-width: 990px) {
    &::before {
      position: absolute;
      // content: "";
      width: 58px;
      height: 30px;
      background-image: url("../assets/img/logo-v3.svg");
      background-repeat: no-repeat;
      background-size: 30px auto;
      top: 15px;
      left: 40px;
    }
  }
  &::before {
    @include tablet {
      left: 24px;
    }
  }
  @include onlyMobile {
    background-color: #f2f3f5;
    border-radius: 16px;
    margin: auto;
  }
}

.modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.8;
  // transition: opacity 0.15s linear;
  @include onlyMobile {
    background-color: #EEEEEE;
    opacity: 1;
  }
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  max-height: 100vh;
  overflow: auto;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  // justify-content: flex-end;
}

.modal-enter-from .modal-backdrop,
.modal-leave-to .modal-backdrop {
  opacity: 0;
}

.modal-enter-from .modal-dialog,
.modal-leave-to .modal-dialog {
  // transform: translate(0, -50px);
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
}

.modal-leave-active {
  transition: opacity 0.3s ease;
}
</style>
