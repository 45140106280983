<template>
  <div id="where">
    <div class="container">
      <div class="where__body">
        <h1 class="title">Куда еще сходить</h1>
        <div class="events__inner">
          <Swiper
            :pagination="{
              el: '.pag-where',
            }"
            :spaceBetween="10"
            :slidesPerView="1"
            :navigation="{
              nextEl: '.where-swiper__right',
              prevEl: '.where-swiper__left',
            }"
          >
            <SwiperSlide v-for="(item, i) of recommendations" :key="i">
              <WhereItem :where="item" />
            </SwiperSlide>
          </Swiper>
          <div class="swiper-pagination pag-where" />
        </div>
        <div class="navigation">
          <button class="button where-swiper__left">
            <img src="@/assets/img/icons/left-v3.svg" />
          </button>
          <button class="button where-swiper__right">
            <img src="@/assets/img/icons/right-v3.svg" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { GET_WHERE } from "@/store/where/types";
import WhereItem from "./WhereItem.vue";
// swiper
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";

SwiperCore.use([Navigation, Pagination]);

export default {
  components: {
    Swiper,
    SwiperSlide,
    WhereItem,
  },
  props: {
    dataPath: {
      type: String,
      default: "izo",
    },
  },
  name: "Where",
  data() {
    return {
      where: null,
    };
  },
  created() {
    this.GET_WHERE(this.dataPath);
    this.where = this.museumWhere;
  },
  computed: {
    ...mapGetters(["museumWhere", "recommendations"]),
  },
  methods: {
    ...mapMutations([GET_WHERE]),
  },
};
</script>

<style lang="scss" scoped>
#where {
  padding-bottom: 48px;
  padding-top: 32px;
  @include onlyMobile {
    padding-bottom: 48px;
    padding-top: 12px;
  }
  .swiper-slide {
    width: fit-content;
  }

  .swiper-container {
    width: 100%;
    border-radius: 12px;
    // padding-bottom: 34px;
  }
  .swiper-pagination {
    display: none;
    @include onlyMobile {
      // bottom: -24px;
      margin-top: 8px;
      display: flex;
      justify-content: flex-end;
    }
  }
  .navigation {
    @include onlyMobile {
      display: none;
    }
  }
}
.events {
  &__inner {
    display: grid;
    grid-template-columns: 1fr;
    // margin-bottom: 45px;
  }
}
.place {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  &__item {
    display: grid;
    grid-template-columns: 17px 1fr;
    column-gap: 11px;
    span {
      font-size: 18px;
      line-height: 22px;
    }
  }
}
.where {
  &__body {
    position: relative;
    background-color: #000000;
    border-radius: 32px;
    padding: 24px;
    @include onlyMobile {
      padding: 12px;
      border-radius: 18px;
    }
    & .title {
      color: #FFFFFF;
      margin: 0 0 16px 0;
    }
    .navigation {
      // margin-top: 32px;
      position: absolute;
      right: 24px;
      bottom: 24px;
      display: flex;
      justify-content: flex-end;
      gap: 8px;
    & .button {
      width: 32px;
      height: 32px;
      padding: 4px;
      background-color: #FFFFFF;
      border-radius: 8px;
    }
  }
  }
}
</style>

<style>
.pag-where .swiper-pagination-bullet-active  {
  background: #FFFFFF!important;
}
</style>