<template>
  <section id="about">
    <div class="container">
      <div class="container__body">
        <img
          v-if="museum.logoUrl"
          :src="`${urlPath}/${museum.logoUrl}`"
          class="logo"
        />
        <p class="container__text">
          {{ museum.description }}
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { GET_INFO } from "@/store/info/types";

export default {
  name: "MuseumInfo",
  props: {
    dataPath: {
      type: String,
      default: "izo",
    },
  },
  data() {
    return {
      about: null,
    };
  },
  created() {
    this.GET_INFO(this.dataPath);
    this.about = this.museumInfo;
  },
  computed: {
    ...mapGetters(["museumInfo", "museum"]),
    urlPath() {
      return process.env.VUE_APP_BASE_API;
    },
  },
  methods: {
    ...mapMutations([GET_INFO]),
  },
};
</script>

<style lang="scss" scoped>
#about {
  padding: 32px 0;
  @include onlyMobile {
    padding: 12px 0 12px 0;
  }
  .logo {
    // margin-bottom: 24px;
    max-width: 210px;
    height: auto;
    @include onlyMobile {
      max-width: 210px;
    }
  }
}

.container {
  &__body {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 100px;
    flex-direction: row-reverse;
    background-color: var(--colorGray);
    border-radius: 32px;
    box-shadow: 0px -10px 22px 0px #00000008;
    @include onlyMobile {
      flex-direction: column;
      gap: 8px;
      align-items: flex-start;
      border-radius: 18px;
      padding: 12px;
    }
  }
  &__text {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    margin: 0;
    max-width: 715px;
    @include onlyMobile {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 20px;
    }
  }
}
</style>
