<template>
  <div id="audioguide" class="audioguide">
    <div class="container">
      <div class="audioguide__body">
        <h1 class="title">Больше не нужно ждать экскурсовода!</h1>
        <div class="desc">
          Пройдите экскурсию с бесплатным 4G-аудиогидом от t2
        </div>
        <div class="audioguide__wrapper">
          <AudioPlayer />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AudioPlayer from "@/components/AudioPlayer.vue";

export default {
  props: {
    dataPath: {
      type: String,
      default: "hermitage",
    },
  },
  components: { AudioPlayer },
  name: "AudioGuide",
  computed: {
    ...mapGetters(["museumHalls", "fetchHalls"]),
  },
};
</script>

<style lang="scss" scoped>
.audioguide {
  // background: #70c9f8;
  padding: 0 0 32px 0;
  position: relative;
  @include desktop {
    // padding: 0 0 32px 0;
  }
  @include onlyMobile {
    padding: 12px 0;
  }
  &__body {
    padding: 24px;
    background-color: var(--colorGray);
    border-radius: 32px;
    // margin-top: 24px;
    position: relative;
    z-index: 10;
    @include onlyMobile {
      padding: 12px;
      border-radius: 18px;
    }
  }
  & h1 {
    margin: 0 0 8px 0;
  }
  & .desc {
    margin: 0 0 8px 0;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    @include onlyMobile {
      font-size: 12px;
      line-height: 16px;
      margin: 0 0 16px 0;
    }
  }
  // &::after {
  //   position: absolute;
  //   top: 100%;
  //   margin-top: -1px;
  //   left: 0;
  //   width: 100%;
  //   background: #70c9f8;
  //   content: "";
  //   pointer-events: none;
  //   clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 0% 100%);
  //   height: 265px;
  //   @include desktop {
  //     clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
  //     height: 294px;
  //   }
  // }
}
</style>
