<template>
  <div id="activation">
    <div class="container activation">
      <div class="activation__body">
        <h1 class="title">
          {{
            dataPath === "hub"
              ? "t2 СОВМЕСТНО С ПАРТНЕРАМИ ПОДГОТОВИЛИ"
              : "АКТИВАЦИИ t2 ДЛЯ ВСЕХ ГОСТЕЙ"
          }}
        </h1>
        <div v-if="$route.path == '/hub'" class="activation__inner">
          <ActivationHubItem
            v-for="(item, i) of hubItems"
            :key="i"
            :item="item"
          />
        </div>
        <div v-else class="activation__inner">
          <ActivationItem
            v-for="(item, i) of museumActivations"
            :key="i"
            :item="item"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { GET_ACTIVATION } from "@/store/activation/types";
import ActivationItem from "./ActivationItem.vue";
import ActivationHubItem from "./ActivationHubItem.vue";

export default {
  components: { ActivationItem, ActivationHubItem },
  name: "Activation",
  props: {
    dataPath: {
      type: String,
      default: "izo",
    },
  },
  data() {
    return {
      activation: null,
    };
  },
  created() {
    this.GET_ACTIVATION(this.dataPath);
    this.activation = this.museumActivation;
  },
  computed: {
    ...mapGetters(["museumActivation", "museumActivations", "hubItems"]),
  },
  methods: {
    ...mapMutations([GET_ACTIVATION]),
  },
};
</script>

<style lang="scss" scoped>
#activation {
  padding: 0 0 32px 0;
  @include onlyMobile {
    padding: 12px 0;
  }
}
.activation {
  &__body {
    background: var(--colorGray);
    padding: 24px;
    border-radius: 32px;
    @include onlyMobile {
      border-radius: 18px;
      padding: 12px;
    }
  }
  &__inner {
    display: grid;
    // grid-template-columns: repeat(2, 1fr);
    grid-template-columns: 1fr;
    column-gap: 36px;
    row-gap: 24px;
    align-items: flex-start;
    @include tablet {
      row-gap: 28px;
    }
    @include onlyMobile {
      row-gap: 16px;
    }
  }
  .title {
    margin: 0 0 16px 0;
    text-transform: none;
    @include onlyMobile {
      margin: 0 0 24px 0;
    }
  }
}
@media screen and (max-width: 1152px) {
  .activation {
    &__inner {
      grid-template-columns: 1fr;
    }
  }
}
</style>
