<template>
  <div class="where-item">
    <div class="where-item__img">
      <div v-if="!where.recommendationImage" class="grey-block" />
      <img
        v-else
        :src="`${urlPath}/${where.recommendationImage}`"
        :alt="where.recommendationImage"
        width="100%"
      />
    </div>
    <div class="content">
      <div>
        <h5 class="title" v-html="where.name" />
        <p class="address" v-html="where.address" />
      </div>
      <router-link
        :to="`/museum/${where.id}`"
        class="btn btn-green"
      >
        <span> Подробнее </span>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    where: {
      type: Object,
    },
  },
  name: "EventItem",
  computed: {
    urlPath() {
      return process.env.VUE_APP_BASE_API;
    },
  },
};
</script>

<style lang="scss" scoped>
.where-item {
  width: 100%;
  @include onlyMobile {
    // width: 200px;
  }
  &__img {
    overflow: hidden;
    width: 100%;
    height: 450px;
    position: relative;
    border-radius: 12px;
    @media(max-width: 1160px) {
      height: 36vw;
    }
    @include onlyMobile {
      // width: 200px;
      // height: 179px;
      // height: 36vw;
    }
    .grey-block {
      width: 100%;
      height: 100%;
      background-color: #f0f0f0;
    }
    img {
      @include onlyMobile {
        // width: 200px;
      }
    }
  }
  .title {
    font-family: var(--fontSecondary);
    font-size: 22px;
    font-weight: 800;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0 0 16px 0;
    @include onlyMobile {
      font-size: 22px;
      line-height: 20px;
      margin: 0 0 4px 0;
    }
  }
  .address {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    margin: 0 0 54px 0;
    opacity: .7;
    color: #FFFFFF;
    // padding-left: 21px;
    position: relative;
    &::before {
      // content: "";
      position: absolute;
      width: 11px;
      height: 13px;
      background-image: url("../assets/img/icons/location.svg");
      left: 0;
      background-size: 11px 13px;
    }
    @include onlyMobile {
      font-size: 12px;
      line-height: 15px;
      margin: 0 0 4px 0;
    }
  }
  .content {
    // width: 322px;
    // min-height: 210px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px 0 0 0;
    // background: #fafafa;
    @include onlyMobile {
      padding: 8px 0 0 0;
      // width: 200px;
      // min-height: 200px;
      // padding: 16px 18px 16px 16px;
    }
    .btn {
      padding: 1.5rem 14rem;
      margin: 0;
      @include onlyMobile {
        padding: 1.5rem 0.6rem;
        width: 100%;
      }
    }
  }
}
</style>
