<template>
  <div class="player" v-if="halls.length">
    <div
      class="player-playlist"
      :class="{ 'player-playlist_type_floor': floor.length }"
      v-if="playlistShow"
    >
      <ul>
        <li v-for="(hall, hallIndex) in halls" :key="hallIndex" class="player-playlist__list-item">
          <div class="player-playlist__title">
            {{ `Зал ${hallIndex + 1}. ${hall.title ? hall.title : ""}` }}
          </div>
          <ul>
            <li
              v-for="(track, trackIndex) in hall.exhibitions"
              :key="trackIndex"
              :class="{
                active:
                  currentHall === hallIndex && currentTrack === trackIndex,
              }"
            >
              <button
                class="player-playlist__button"
                @click="onChangeTrack(hallIndex, trackIndex)"
              >
                <!-- {{ `${track.number}. ${track.title}` }} -->
                {{ `${track.title}` }}
              </button>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="player__head">
      <div class="player-nav">
        <div class="player-nav__floor" id="floor">
          <button
            v-for="(item, i) of floor"
            :key="i"
            :class="{ active: item == activeFloor }"
            @click="onChangeFloor(item)"
          >
            {{ item }}
          </button>
        </div>
        <div class="player-nav__title">Доступные залы</div>
        <div class="player-nav__items">
          <MultiButton
            class="short"
            :buttons="normalizeHalls"
            :selected="currentHall + 1"
            @select="onChangeHall"
          />
        </div>
      </div>
      <button
        class="player-button-more"
        :class="{ active: playlistShow }"
        @click="playlistShow = !playlistShow"
      >
        Экспонаты
      </button>
      <div class="player-playlist__list-wrap">
        <ul>
        <li v-for="(hall, hallIndex) in halls" :key="hallIndex" class="player-playlist__list-item">
          <div class="player-playlist__title">
            {{ `Зал ${hallIndex + 1}. ${hall.title ? hall.title : ""}` }}
          </div>
          <ul>
            <li
              v-for="(track, trackIndex) in hall.exhibitions"
              :key="trackIndex"
              :class="{
                active:
                  currentHall === hallIndex && currentTrack === trackIndex,
              }"
            >
              <button
                class="player-playlist__button"
                @click="onChangeTrack(hallIndex, trackIndex)"
              >
                <!-- {{ `${track.number}. ${track.title}` }} -->
                {{ `${track.title}` }}
              </button>
            </li>
          </ul>
        </li>
      </ul>
      </div>
    </div>
    <div class="player__wrap">
      <div class="player__body">
      <div class="player-preview">
        <div class="player-preview__pic">
          <img
            v-show="previewShow"
            :src="`${urlPath}/${getCurrentTrack.imageLink}`"
            alt=""
            width="270"
            height="270"
          />
          <button class="player-preview__button" @click="openModal" />
        </div>
      </div>
    </div>
    <div class="player__foot">
      <div class="player-detail">
        <div class="player-detail__title" :title="getTrackName">
          {{ getTrackName }}
        </div>
        <div class="player-detail__desc" :title="getHallName">
          {{ getHallName }}
        </div>
        <div class="player-controls">
          <button
            class="player-controls__button player-controls__prev"
            :disabled="isBeginedPlaylist"
            @click="onPrev"
          ></button>
          <button
            class="player-controls__button player-controls__play"
            :class="{
              pause: playing,
            }"
            :disabled="loading"
            @click="onPlay"
          >
            <Loader v-model="loading" />
          </button>
          <button
            class="player-controls__button player-controls__next"
            :disabled="isEndedPlaylist"
            @click="onNext"
          ></button>
        </div>
        <div class="player-timeline">
          <div class="player-timeline__head">
            <span>{{ fancyTimeFormat(currentTime) }}</span>
            <span>{{ fancyTimeFormat(duration) }}</span>
          </div>
          <div class="player-timeline__body">
            <span :style="{ width: getCurrentTimeInPercent }"></span>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
  <transition name="modal">
    <Modal v-if="modalShow" @close="modalShow = false">
      <template #body>
        <div class="player-modal">
          <div class="player-modal__pic">
            <img
              :src="`${urlPath}/${getCurrentTrack.previewImageLink}`"
              alt=""
              width="386"
              height="386"
            />
          </div>
          <div class="player-modal__content">
            <div class="player-modal__title">{{ getCurrentTrack.title }}</div>
            <div
              class="player-modal__desc"
              v-html="getCurrentTrack.description"
            ></div>
            <div class="player-modal__caption">{{ getHallName }}</div>
          </div>
        </div>
      </template>
    </Modal>
  </transition>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { CHANGE_STATE } from "@/store/modal/types";
import { GET_HALLS } from "@/store/audio/types";

import MultiButton from "@/components/MultiButton";
import Loader from "@/components/Loader";
import Modal from "@/components/Modal.vue";

export default {
  name: "Player",
  components: {
    MultiButton,
    Loader,
    Modal,
  },
  props: {
    dataPath: {
      type: String,
      default: "hermitage",
    },
  },
  data() {
    return {
      floor: [],
      activeFloor: null,
      halls: [],
      normalizeFetchData: [],
      currentHall: 0,
      currentTrack: 0,

      audio: new Audio(),
      duration: 0,
      currentTime: 0,

      playlistShow: false,
      previewShow: true,
      playing: false,
      loading: false,

      modalShow: false,
    };
  },
  computed: {
    ...mapGetters(["fetchHalls"]),
    urlPath() {
      return process.env.VUE_APP_BASE_API;
    },
    isBeginedPlaylist() {
      return this.currentHall === 0 && this.currentTrack === 0;
    },
    isEndedPlaylist() {
      return (
        this.currentHall === this.halls.length - 1 &&
        this.currentTrack ===
          this.halls[this.currentHall].exhibitions.length - 1
      );
    },
    getTrackName() {
      // return `${this.getCurrentTrack.number}. ${this.getCurrentTrack.title}`;
      return `${this.getCurrentTrack.title}`;
    },
    getHallName() {
      return `Зал ${this.currentHall + 1}. ${
        this.halls[this.currentHall].title
          ? this.halls[this.currentHall].title
          : ""
      }`;
    },
    getCurrentTrack() {
      return this.halls.length
        ? this.halls[this.currentHall].exhibitions[this.currentTrack]
        : {};
    },
    getCurrentTimeInPercent() {
      return `${Math.ceil((this.currentTime / this.duration) * 100)}%`;
    },
    normalizeHalls() {
      return this.halls.map((e, i) => {
        return { name: i + 1, index: i };
      });
    },
    ftpPath() {
      return process.env.VUE_APP_MEDIA_FTP;
    },
  },
  watch: {
    activeFloor(newVal, oldVal) {
      if (newVal !== oldVal) {
        // скрываем залы, в которых нет треков
        this.halls = this.normalizeFetchData.filter(
          (el) => el.floor == newVal && !el.hidden
        );
        this.currentTrack = 0;
      }
    },
    getCurrentTrack() {
      const { audioLink } = this.getCurrentTrack;

      this.loading = true;
      this.audio.src = `${process.env.VUE_APP_BASE_API}/${audioLink}`;

      this.flickering();
    },
  },
  async mounted() {
    // setTimeout(() => {
    // this.GET_HALLS(this.dataPath);
    this.halls = this.normalizeData(this.sortExhibitions(this.fetchHalls));
    this.init();
    // }, 1000);
  },
  beforeUnmount() {
    this.destroy();
  },
  methods: {
    ...mapMutations([CHANGE_STATE, GET_HALLS]),
    openModal() {
      this.modalShow = true;
      this.CHANGE_STATE(true);
    },
    init() {
      const { audioLink } = this.getCurrentTrack;

      this.audio.addEventListener("loadedmetadata", this.onWard);
      // this.audio.addEventListener("loadedmetadata", this.setDuration);
      // this.audio.addEventListener("canplay", this.onWard);
      this.audio.addEventListener("ended", this.onNext);
      this.audio.addEventListener("timeupdate", this.updateCurrentTime);
      this.audio.addEventListener("error", this.onNext);

      this.audio.src = audioLink;
    },
    destroy() {
      this.audio.removeEventListener("loadedmetadata", this.onWard);
      // this.audio.removeEventListener("loadedmetadata", this.setDuration);
      // this.audio.removeEventListener("canplay", this.onWard);
      this.audio.removeEventListener("ended", this.onNext);
      this.audio.removeEventListener("timeupdate", this.updateCurrentTime);
      this.audio.removeEventListener("error", this.onNext);
    },
    flickering() {
      this.previewShow = false;
      setTimeout(() => {
        this.previewShow = true;
      }, 0);
    },
    sortExhibitions(halls) {
      return halls.map((hall) => {
        hall.exhibitions.sort((a, b) => a.order - b.order);
        return hall;
      });
    },
    normalizeData(data) {
      let trackIndex = 0;
      // добавляем нумерацию залам
      let floor = [];
      for (let index = 0; index < data.length; index++) {
        const hall = data[index];
        hall.number = this.fancyNumberFormat(index + 1);
        if (hall.floor) {
          floor = [...floor, hall.floor];
        }
        if (!hall.exhibitions.length) hall.hidden = true;
        // добавляем нумерацию трекам
        for (let index2 = 0; index2 < hall.exhibitions.length; index2++) {
          const track = hall.exhibitions[index2];
          // номер по названию аудио
          // track.number = track.source[0] + track.source[1];
          // это пока не надо, тк номер в title
          track.number = this.fancyNumberFormat(trackIndex + 1);
          trackIndex++;
        }
      }
      this.normalizeFetchData = data;
      // чистим дубликаты и скрываем floor, в которых нет треков
      this.floor = this.cleanEmptyFloor(floor);
      this.activeFloor = this.floor[0];
      return this.normalizeFetchData.filter(
        (el) => el.floor == this.activeFloor
      );
    },
    cleanEmptyFloor(floor) {
      let new_floor = [...new Set(floor)];
      let arr = [];
      for (let i = 0; i < new_floor.length; i++) {
        let filter = this.normalizeFetchData.filter(
          (el) => el.floor == new_floor[i]
        );
        const find = filter.find((el) => !el.hidden);
        if (find) arr.push(floor[i]);
      }
      return arr;
    },
    updateCurrentTime() {
      this.currentTime = Math.round(this.audio.currentTime);
    },
    fancyNumberFormat(index) {
      return `0${index}`.slice(-2);
    },
    fancyTimeFormat(s) {
      return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
    },
    onPrev() {
      const halls = this.halls;

      if (this.isBeginPlaylist) {
        return false;
      }

      if (this.currentTrack !== 0) {
        this.currentTrack--;
      } else {
        this.currentHall--;
        this.currentTrack = halls[this.currentHall].exhibitions.length - 1;
      }
    },
    onNext() {
      const halls = this.halls;
      const tracks = halls[this.currentHall].exhibitions;

      if (this.isEndedPlaylist) {
        this.playing = false;
        return false;
      }

      if (tracks.length > this.currentTrack + 1) {
        this.currentTrack++;
      } else if (halls.length > this.currentHall + 1) {
        this.currentHall++;
        this.currentTrack = 0;
      }
    },
    onPlay() {
      this.playing ? this.audio.pause() : this.audio.play();
      this.playing = !this.playing;
    },
    onWard() {
      this.loading = false;
      this.duration = Math.round(this.audio.duration);
      if (this.playing) {
        this.audio.play();
      }
    },
    onChangeHall({ index }) {
      this.currentHall = index;
      this.currentTrack = 0;
      this.playing = true;
    },
    onChangeTrack(hallIndex, trackIndex) {
      this.currentHall = hallIndex;
      this.currentTrack = trackIndex;
      this.playing = true;
    },
    onChangeFloor(item) {
      this.currentHall = 0;
      this.currentTrack = 0;
      this.playing = true;
      this.activeFloor = item;
    },
  },
};
</script>

<style lang="scss" scoped>
.player {
  position: relative;
  background: #ffffff;
  border-radius: 24px;
  padding: 12px;
  padding-bottom: 16px;
  display: grid;
  gap: 15px;
  box-shadow: 0px 2px 16px 0px #0000000F;
  @include desktop {
    grid-template-columns: 50% 40%;
    gap: 0;
    // align-items: center;
    justify-content: space-between;
    padding: 24px;
  }
  @include onlyMobile {
    grid-template-columns: 100%;
    border-radius: 18px;
    gap: 6px;
  }
  &__head {
    @include desktop {
      height: 100%;
    }
    div {
      ul {
        @include onlyMobile {
          display: none;
        }
        li {
          ul {
            li.active {
              button {
                color: #000000;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  &__foot {
    width: 100%;
    overflow: hidden;
    margin-top: 16px;
  }
  &-playlist {
    position: absolute;
    top: 135px;
    left: 0;
    z-index: 10;
    background-color: #fff;
    width: 100%;
    height: calc(100% - 135px);
    overflow: auto;
    padding: 12px;
    padding-bottom: 0;
    border-radius: 8px;
    &_type_floor {
      top: 185px;
      height: calc(100% - 185px);
      @include onlyMobile {
        top: 210px;
        height: calc(100% - 210px);
      }
    }
    @include desktop {
      top: 0;
      left: 425px;
      height: 100%;
      width: calc(100% - 425px);
    }
    &__title {
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      color: #1F2229;
      opacity: 0.7;
      padding-top: 8px;
      padding-bottom: 8px;
      @include onlyMobile {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        padding-bottom: 4px;
      }
    }
    &__list-item {
      margin-bottom: 8px;
    }
    &__list-wrap {
      max-height: 360px;
      overflow: auto;
    }
    &__button {
      @include reset-button;
      font-family: var(--fontPrimary);
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      color: #1F2229;
      opacity: 0.7;
      text-align: left;
      padding-right: 12px;
      padding-bottom: 8px;
      @include onlyMobile {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        padding-bottom: 4px;
      }
    }
    & > ul {
      & > li {
        & > ul {
          & > li {
            border-left: 1px solid transparent;
            // margin: 16px 0;
            &.active {
              border-left-color: var(--mainColor);
              & > button {
                // transform: translateX(12px);
                color: #000000;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  &-nav {
    margin-bottom: 24px;
    @include onlyMobile {
      margin-bottom: 0;
    }
    &__title {
      // font-family: var(--fontSerif);
      font-size: 15px;
      font-weight: 400;
      line-height: 16px;

    }
    &__items {
      margin-top: 8px;
      @media (max-width: 425px) {
        width: 100%;
        overflow-x: scroll;
      }
      .short {
        gap: 3px;
        flex-wrap: nowrap;
        // width: 276px;
        // @include onlyMobile {
        //   width: fit-content;
        // }
      }
    }
    &__floor {
      max-width: 325px;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 7px;
      align-items: flex-start;
      @media (max-width: 425px) {
        width: 100%;
        overflow-x: scroll;
      }
      button {
        @include reset-button;
        width: fit-content;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        text-align: center;
        color: #333;
        // border-bottom: 1px dashed #70c9f8;
        position: relative;
        text-align: center;
        margin-bottom: 18px;
        background-color: #F2F3F5;
        border-radius: 400px;
        padding: 7px 16px;
        &.active {
          cursor: default;
          color: #000;
          border-bottom: none;
          background-color: #B9F94F;
        }
      }
    }
  }
  &-button-more {
    display: none;
    @include reset-button;
    position: relative;
    font-size: 14px;
    line-height: 1;
    padding: 10px;
    padding-left: 32px;
    margin-top: 16px;
    @include desktop {
      margin-top: 60px;
    }
    @include onlyMobile {
      display: block;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      padding-left: 20px;
      margin-top: 8px;
    }
    &::before {
      background-image: url("data:image/svg+xml,%0A%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath clip-rule='evenodd' d='M1 4.5C1 1.8748 1.0281 1 4.5 1S8 1.8748 8 4.5 8.011 8 4.5 8 1 7.1252 1 4.5zM12 4.5c0-2.6252.0281-3.5 3.5-3.5s3.5.8748 3.5 3.5.0111 3.5-3.5 3.5S12 7.1252 12 4.5zM1 15.5c0-2.6252.0281-3.5 3.5-3.5s3.5.8748 3.5 3.5.011 3.5-3.5 3.5S1 18.1252 1 15.5zM12 15.5c0-2.6252.0281-3.5 3.5-3.5s3.5.8748 3.5 3.5.0111 3.5-3.5 3.5-3.5-.8748-3.5-3.5z' stroke='%231F2229' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      content: "";
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      @include onlyMobile {
        background-image: url("data:image/svg+xml,%0A%3Csvg width='12' height='12' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath clip-rule='evenodd' d='M1 4.5C1 1.8748 1.0281 1 4.5 1S8 1.8748 8 4.5 8.011 8 4.5 8 1 7.1252 1 4.5zM12 4.5c0-2.6252.0281-3.5 3.5-3.5s3.5.8748 3.5 3.5.0111 3.5-3.5 3.5S12 7.1252 12 4.5zM1 15.5c0-2.6252.0281-3.5 3.5-3.5s3.5.8748 3.5 3.5.011 3.5-3.5 3.5S1 18.1252 1 15.5zM12 15.5c0-2.6252.0281-3.5 3.5-3.5s3.5.8748 3.5 3.5.0111 3.5-3.5 3.5-3.5-.8748-3.5-3.5z' stroke='%231F2229' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
        width: 12px;
        height: 12px;
      }
    }
    &::after {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.7918.2096a.709.709 0 00-1.0026 0L9.0007 7.9981 1.2123.2096A.709.709 0 10.2096 1.2123l7.7885 7.7884-7.7885 7.7884a.709.709 0 001.0027 1.0027l7.7884-7.7884 7.7885 7.7884a.7089.7089 0 001.0026 0 .7089.7089 0 000-1.0026l-7.7884-7.7885 7.7884-7.7884a.709.709 0 000-1.0027z' fill='%231F2229'/%3E%3C/svg%3E");
      width: 20px;
      height: 20px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      @include onlyMobile {
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.7918.2096a.709.709 0 00-1.0026 0L9.0007 7.9981 1.2123.2096A.709.709 0 10.2096 1.2123l7.7885 7.7884-7.7885 7.7884a.709.709 0 001.0027 1.0027l7.7884-7.7884 7.7885 7.7884a.7089.7089 0 001.0026 0 .7089.7089 0 000-1.0026l-7.7884-7.7885 7.7884-7.7884a.709.709 0 000-1.0027z' fill='%231F2229'/%3E%3C/svg%3E");
        width: 12px;
        height: 12px;
      }
    }
    &.active {
      &::before {
        content: none;
      }
      &::after {
        content: "";
      }
    }
  }
  &-preview {
    &__pic {
      padding: 12px;
      position: relative;
      // border: 1px solid rgba(196, 196, 196, 0.5);
      box-sizing: border-box;
      border-radius: 12px;
      max-width: 411px;
      height: 251px;
      margin: auto;
      background-color: #F2F3F5;
      > img {
        object-fit: contain;
        object-position: 50% 50%;
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    &__button {
      display: none;
      @include onlyMobile {
        display: block;
      }
      @include reset-button;
      position: absolute;
      top: 12px;
      right: 12px;
      width: 28px;
      height: 28px;

      background-color: #ffffff;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M.6269 5.4407a.6268.6268 0 00.6269-.6269V2.141l4.0375 4.0368a.625.625 0 00.4433.1835.6267.6267 0 00.4432-1.0701L2.1401 1.2543h2.6735a.6269.6269 0 000-1.2538H.6269A.6268.6268 0 000 .6274v4.1865c0 .3461.2806.6268.6269.6268zM17.3726 12.5603a.6269.6269 0 00-.6269.6269v2.6729l-4.1618-4.1618a.6269.6269 0 10-.8866.8865l4.1618 4.1617h-2.6728a.6269.6269 0 100 1.2538h4.1864a.6269.6269 0 00.6269-.6269v-4.1862a.627.627 0 00-.627-.6269zM5.4152 11.6983L1.2538 15.86v-2.6728a.6269.6269 0 10-1.2538 0v4.1864c0 .3462.2806.6269.6269.6269h4.1863a.627.627 0 000-1.2538h-2.673l4.1616-4.1619a.6269.6269 0 10-.8866-.8865zM17.3727.0005h-4.1864a.6269.6269 0 100 1.2538h2.673l-4.037 4.0372a.6269.6269 0 10.8866.8865l4.037-4.0372v2.673a.6269.6269 0 101.2538 0V.6274a.627.627 0 00-.627-.627z' fill='%23808080'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
  }
  &-detail {
    &__title {
      font-family: var(--fontPrimary);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
    }
    &__desc {
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      opacity: 0.5;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top: 8px;
    }
  }
  &-controls {
    margin-top: 16px;
    text-align: center;
    display: flex;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    &__button {
      @include reset-button;
      width: 54px;
      height: 54px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      transition: 0.2s;
      &:disabled {
        opacity: 0.3;
        cursor: default;
      }
      &:active {
        transform: scale(0.85);
      }
    }
    &__prev,
    &__next {
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.6742 7.8439c.6175-.393.6175-1.2944 0-1.6873L1.537.9782C.8712.5546 0 1.0328 0 1.822v10.3567c0 .789.8711 1.2673 1.5369.8436l8.1373-5.1783z' fill='%231F2229'/%3E%3Crect x='12' width='2' height='14' rx='1' fill='%231F2229'/%3E%3C/svg%3E");
    }
    &__prev {
      transform: rotate(180deg);
      &:active {
        transform: scale(0.85) rotate(180deg);
      }
    }
    &__play {
      position: relative;
      display: grid;
      place-items: center;
      background-image: url("data:image/svg+xml,%3Csvg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='27' cy='27' r='27' fill='%231F2229'/%3E%3Cpath d='M35.6432 27.8481c.6267-.3917.6267-1.3043 0-1.696L22.53 17.9563c-.6661-.4162-1.53.0626-1.53.848v16.3915c0 .7855.864 1.2643 1.53.848l13.1132-8.1957z' fill='%23fff'/%3E%3C/svg%3E");
      &:disabled {
        opacity: 1;
      }
      &.pause {
        background-image: url("data:image/svg+xml,%3Csvg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='27' cy='27' r='27' fill='%231F2229'/%3E%3Cpath d='M21 19c0-.5523.4477-1 1-1h1c.5523 0 1 .4477 1 1v16c0 .5523-.4477 1-1 1h-1c-.5523 0-1-.4477-1-1V19zM30 19c0-.5523.4477-1 1-1h1c.5523 0 1 .4477 1 1v16c0 .5523-.4477 1-1 1h-1c-.5523 0-1-.4477-1-1V19z' fill='%23fff'/%3E%3C/svg%3E");
      }
      &.loader {
        background: none;
      }
      > svg {
        background: #1f2229;
        border-radius: 20px;
      }
    }
  }
  &-timeline {
    margin-top: 8px;
    display: grid;
    row-gap: 16px;
    &__head {
      display: flex;
      justify-content: space-between;
      > span {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
    &__body {
      background: #c4c4c4;
      height: 1px;
      > span {
        display: block;
        height: 100%;
        background: var(--mainColor);
        transition: width 0.15s;
      }
    }
  }
  &-modal {
    padding: 0 12px;
    @media (min-width: 990px) {
      padding: 0 46px;
      display: grid;
      grid-auto-flow: column;
      gap: 75px;
    }
    &__pic {
      position: relative;
      height: 0;
      padding-bottom: 100%;
      // border: 1px solid rgba(196, 196, 196, 0.5);
      background-color: #F2F3F5;
      box-sizing: border-box;
      border-radius: 12px;
      overflow: hidden;
      @media (min-width: 990px) {
        width: 386px;
      }
      img {
        position: absolute;
        top: 12px;
        left: 0;
        width: 100%;
        height: 93%;
        object-fit: contain;
        object-position: 50%;
      }
    }
    &__content {
      display: grid;
      gap: 16px;
      margin-top: 16px;
      grid-auto-rows: max-content;
    }
    &__title {
      font-family: var(--fontPrimary);
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;

    }
    &__desc {
      // font-size: 18px;
      // line-height: 140%;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }
    &__caption {
      font-size: 14px;
      line-height: 17px;
      color: #1f2229;
      opacity: 0.7;
    }
  }
}

// .active {
//   border: 1px solid red;
// }
</style>

<style>
.player + .modal > .modal-dialog > .modal-content {
  background-color: #fff;
}
.player-nav__items .short button {
  color: #000000;
}

</style>