<template>
  <div class="activation-item">
    <!-- <img
      v-if="item.imageLink"
      :src="`${urlPath}/${item.imageLink}`"
      :alt="item.image"
    />
    <div v-else /> -->
    <div>
      <h5 class="title">{{ item.text }}</h5>
      <p v-html="item.description" />
      <a
        v-if="item.buttonListen"
        href="#audioguide"
        v-scroll-to="{
          el: '#audioguide',
          container: 'body',
          duration: 500,
          easing: 'ease',
          offset: -76,
          force: true,
          cancelable: true,
          onStart: false,
          onDone: false,
          onCancel: false,
          x: false,
          y: true,
        }"
      >
        Слушать
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
  name: "ActivationItem",
  computed: {
    urlPath() {
      return process.env.VUE_APP_BASE_API;
    },
  },
};
</script>

<style lang="scss" scoped>
.activation-item {
  display: grid;
  // grid-template-columns: 64px 1fr;
  grid-template-columns: 1fr;
  column-gap: 16px;
  // max-width: 520px;
  width: 100%;
  @include tablet {
    // grid-template-columns: 40px 1fr;
  }
  img {
    margin: 0 auto;
    display: block;
    max-height: 64px;
    max-width: 64px;
    width: auto;
    height: auto;
    @include tablet {
      max-height: 40px;
    }
  }
  h5 {
    font-family: var(--fontPrimary);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    margin: 0 0 4px 0;
    @include tablet {
      font-size: 16px;
      line-height: 23px;
      margin: 0 0 8px 0;
    }
    @include onlyMobile {
      font-size: 15px;
      line-height: 20px;
      margin: 0 0 4px 0;
    }
  }
  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    color:  #1F2229;
    margin: 0;
    max-width: 95%;
    @include tablet {
      font-size: 14px;
    }
    @include onlyMobile {
      font-size: 12px;
      line-height: 16px;
    }
  }
  a {
    display: block;
    text-decoration: underline;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    margin-top: 4px;
    @include tablet {
      margin-top: 8px;
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 1152px) {
  .activation-item {
    margin: 0 auto;
  }
}
</style>
