<template>
  <div id="address">
    <div class="container">
      <h1 class="title">График работы и адрес</h1>
      <div class="address">
        <div class="address__item">
          <!-- <img src="@/assets/img/icons/clock.svg" alt="clock" /> -->
          <!-- <span v-html="`График работы музея:<br />${museum.schedule}`" /> -->
           <span>График работы музея:</span>
           <span v-html="`${museum.schedule}`" />
        </div>
        <a :href="museum.mapLink" target="_blank" class="address__item">
          <!-- <img src="@/assets/img/icons/location.svg" alt="location" /> -->
          <!-- <span v-html="`Адрес:<br />${museum.address}`" /> -->
           <span>Адрес:</span>
          <span v-html="`${museum.address}`" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { GET_ADDRESS } from "@/store/address/types";

export default {
  name: "Address",
  props: {
    dataPath: {
      type: String,
      default: "izo",
    },
  },
  data() {
    return {
      address: null,
    };
  },
  created() {
    this.GET_ADDRESS(this.dataPath);
    this.address = this.museumAddress;
  },
  computed: {
    ...mapGetters(["museumAddress", "museum"]),
  },
  methods: {
    ...mapMutations([GET_ADDRESS]),
  },
};
</script>

<style lang="scss" scoped>
#address {
  @include onlyMobile {
    padding: 24px 0 12px;
  }
  .title {
    margin: 0 0 16px 0;
    @include onlyMobile {
      margin: 0 0 20px 0;
    }
  }
  .address {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    @include onlyMobile {
      gap: 8px;
    }
    &__item {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
      column-gap: 11px;
      width: 100%;
      height: 200px;
      background-color: var(--colorGray);
      padding: 24px;
      border-radius: 32px;
      @include tablet {
        height: 148px;
      }
      @include onlyMobile {
        height: 148px;
        padding: 12px;
        border-radius: 16px;
      }
      span:nth-child(1) {
        font-family: var(--fontSecondary);
        font-size: 16px;
        font-weight: 800;
        line-height: 14px;
        text-transform: uppercase;
      }
      span:nth-child(2) {
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        align-self: flex-end;
        @include onlyMobile {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}
</style>
