<template>
  <div id="events">
    <div class="container">
      <div class="events__body">
        <h1 class="title">Выставки и мероприятия</h1>
      <div class="events__inner">
        <Swiper
          :pagination="{
            el: '.pag-event',
          }"
          :spaceBetween="10"
          :slidesPerView="1"
          :navigation="{
            nextEl: '.events-swiper__right',
            prevEl: '.events-swiper__left',
          }"
        >
          <SwiperSlide v-for="(event, i) of fetchEvents" :key="i">
            <EventItem :event="event" @open-modal="onOpenModal" />
          </SwiperSlide>
        </Swiper>
        <div class="swiper-pagination pag-event" />
      </div>
      <div class="navigation">
          <button class="button events-swiper__left">
            <img src="@/assets/img/icons/left-v2.svg" />
          </button>
          <button class="button events-swiper__right">
            <img src="@/assets/img/icons/right-v2.svg" />
          </button>
        </div>
      </div>
    </div>
  </div>
  <transition name="modal">
    <Modal v-if="modalShow" @close="modalShow = false">
      <template #body>
        <div class="content events-modal">
          <img
            :src="`${urlPath}/${modalContent.previewImageLink}`"
            :alt="modalContent.previewImageLink"
            width="320"
            class="a"
          />
          <div v-if="modalContent.onlySubs" class="for">
            <span>Для абонентов <span>t2</span></span>
          </div>
          <h5 class="title b">{{ modalContent.title }}</h5>
          <p class="dates c">{{ modalContent.schedule }}</p>
          <div class="d">
            <p class="desc">{{ modalContent.description }}</p>
            <p class="desc">{{ modalContent.fullDescription }}</p>
          </div>
          <a
            :href="modalContent.timePadLink"
            target="_blank"
            class="btn btn-green e"
          >
            Получить бесплатный билет
          </a>
        </div>
      </template>
    </Modal>
  </transition>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import { CHANGE_STATE } from "@/store/modal/types";
import { GET_EVENTS } from "@/store/events/types";
import EventItem from "./EventItem.vue";

import Modal from "@/components/Modal.vue";

// swiper
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";

SwiperCore.use([Navigation, Pagination]);

export default {
  components: { EventItem, Swiper, SwiperSlide, Modal },
  name: "Events",
  props: {
    dataPath: {
      type: String,
      default: "izo",
    },
  },
  data() {
    return {
      events: null,
      modalShow: false,
      modalContent: null,
    };
  },
  created() {
    this.GET_EVENTS(this.dataPath);
    this.events = this.museumEvents;
  },
  computed: {
    ...mapGetters(["museumEvents", "fetchEvents"]),
    urlPath() {
      return process.env.VUE_APP_BASE_API;
    },
  },
  methods: {
    ...mapMutations([CHANGE_STATE, GET_EVENTS]),
    onOpenModal(content) {
      this.modalContent = content;
      this.modalShow = true;
      this.CHANGE_STATE(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.events-modal {
  padding: 0 46px;
  @include tablet {
    padding: 0;
  }
  &.content {
    padding: 0 12px;
    position: relative;
    display: grid;
    grid-template-areas:
      "a d"
      "b d"
      "c d"
      "e d";
    column-gap: 58px;
    grid-template-columns: 320px 1fr;
    @include tablet {
      grid-template-columns: 1fr;
      grid-template-areas: "a" "f" "b" "d" "c" "e";
    }
    img {
      margin-bottom: 24px;
      border-radius: 12px;
      max-width: 100%;
      width: 100%;
      height: auto;
      @include tablet {
        margin: 0 auto 16px auto;
      }
    }
    .for {
      // position: absolute;
      // width: 164px;
      // height: 24px;
      // background-image: url("../assets/img/icons/for-tele2.svg");
      // background-repeat: no-repeat;
      // z-index: 1;
      // top: 0;
      // left: 46px;
      // display: flex;
      // align-items: center;
      display: inline-block;
      width: fit-content;
      background-color: #B9F94F;
      border-radius: 400px;
      padding: 2px 11px;
      @include tablet {
        grid-area: f;
        position: inherit;
        margin-bottom: 8px;
        // margin-left: 24px;
        left: 0;
      }
      span {
        color: #000000;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        text-align: center;
        // margin-left: 12px;
        span {
          text-transform: none;
        }
      }
    }
    .a {
      grid-area: a;
    }
    .b {
      grid-area: b;
      font-family: var(--fontSecondary);
      font-size: 22px;
      font-weight: 800;
      line-height: 20px;
      margin: 0;
      margin-bottom: 8px;
      @include tablet {
        // margin-left: 24px;
        // margin-right: 24px;
      }
    }
    .c {
      grid-area: c;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      margin: 0;
      margin-bottom: 32px;
      // opacity: 0.5;
      @include tablet {
        // font-size: 12px;
        // line-height: 15px;
        margin-bottom: 8px;
        // margin-left: 24px;
        // margin-right: 24px;
      }
    }
    .d {
      grid-area: d;
      .desc {
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        margin-top: 0;
        margin-bottom: 8px;
        @include tablet {
          // font-size: 14px;
          // line-height: 140%;
          // margin-left: 24px;
          // margin-right: 24px;
        }
      }
    }
    .e {
      grid-area: e;
      padding: 1.5rem 0rem;
      width: auto;
      font-size: 15px;
      font-weight: 800;
      line-height: 20px;
      @include tablet {
        // max-width: 271px;
        // margin-left: 24px;
        // margin-right: 24px;
      }
    }
  }
}
#events {
  padding-top: 32px;
  padding-bottom: 32px;
  @include onlyMobile {
    padding: 12px 0;
  }

   .title {
    margin: 0 0 16px 0;
   }

  .swiper-slide {
    width: fit-content;
  }

  .swiper-container {
    width: 100%;
    border-radius: 12px;
    // padding-bottom: 34px;
  }
  .swiper-pagination {
    display: none;
    @include onlyMobile {
      // bottom: -24px;
      margin-top: 8px;
      display: flex;
      justify-content: flex-end;
    }
  }

  .navigation {
    @include onlyMobile {
      display: none;
    }
  }
}
.events {
  &__body {
    background-color: var(--colorGray);
    border-radius: 32px;
    padding: 24px;
    position: relative;
    @include onlyMobile {
      border-radius: 18px;
      padding: 12px;
    }
    .navigation {
      display: flex;
      gap: 8px;
      position: absolute;
      right: 24px;
      bottom: 24px;
      .button {
        width: 32px;
        height: 32px;
        padding: 4px;
        background-color: #000;
        border-radius: 8px;
      }
    }
  }
  &__inner {
    display: grid;
    grid-template-columns: 1fr;
    // margin-bottom: 45px;
  }
}
</style>

