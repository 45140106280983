<template>
  <main v-if="!loading" id="main">
    <Header v-if="show" />
    <Teaser
      :path-desktop="museum.desktopImage"
      :path-tablet="museum.tabletImage"
      :path-mobile="museum.mobileImage"
      :path-mobile-text="museum.age"
      :color="museum.color"
    />
    <MuseumInfo />
    <Ticket /> 
    <Activation v-if="museumActivations.length" />
    <AudioGuide v-if="fetchHalls.length" />
    <Video v-if="videos.online_ex.length || videos.lectures.length" />
    <Events v-if="fetchEvents.length" />
    <Address v-if="museum.address && museum.schedule" />
    <Where v-if="museum.recommendations.length" />
  </main>
  <div class="page-loader" v-else>
    <Loader v-model="loading" color="#70c9f8" :size="70" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { GET_MUSEUM, GET_RECOMMENDATION } from "@/store/museums/types";
import { FETCH_ACTIVATIONS } from "@/store/activation/types";
import { FETCH_VIDEOS } from "@/store/video/types";
import { FETCH_HALLS } from "@/store/audio/types";
import { FETCH_EVENTS } from "@/store/events/types";
import Header from "@/components/Header";
import Teaser from "@/components/Teaser.vue";
import MuseumInfo from "@/components/MuseumInfo.vue";
import Ticket from "@/components/Ticket.vue";
import AudioGuide from "@/components/AudioGuide.vue";
import Activation from "@/components/Activation.vue";
import Video from "@/components/Video.vue";
import Events from "@/components/Events.vue";
import Where from "@/components/Where.vue";
import Address from "@/components/Address.vue";
import Loader from "@/components/Loader.vue";

export default {
  name: "Naiv",
  components: {
    Teaser,
    MuseumInfo,
    Header,
    Ticket,
    Activation,
    Video,
    Events,
    Where,
    Address,
    AudioGuide,
    Loader,
  },
  data() {
    return {
      loading: true,
      show: true,
    };
  },
  async created() {
    await this.init();
  },
  watch: {
    async "$route.path"(val) {
      this.show = false;
      if (val !== "/hub") {
        await this.init();
      }
      this.show = true;
    },
  },
  computed: {
    ...mapGetters([
      "museum",
      "museumActivations",
      "videos",
      "fetchHalls",
      "fetchEvents",
    ]),
  },
  methods: {
    ...mapActions([
      GET_MUSEUM,
      FETCH_ACTIVATIONS,
      FETCH_VIDEOS,
      FETCH_HALLS,
      FETCH_EVENTS,
      GET_RECOMMENDATION,
    ]),
    async init() {
      this.loading = true;
      await this.GET_MUSEUM(this.$route.params.id);
      await this.FETCH_ACTIVATIONS(this.$route.params.id);
      await this.FETCH_VIDEOS({ id: this.$route.params.id, type: 1 });
      await this.FETCH_HALLS(this.$route.params.id);
      await this.FETCH_VIDEOS({ id: this.$route.params.id, type: 0 });
      await this.FETCH_EVENTS(this.$route.params.id);
      if (this.museum.recommendations.length) {
        await this.GET_RECOMMENDATION(this.museum.recommendations);
      }
      this.loading = false;
    },
  },
};
</script>
