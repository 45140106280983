<template>
  <section id="ticket">
    <div class="container ticket">
      <!-- <img src="@/assets/img/ticket.svg" alt="ticket" class="ticket__img" /> -->
      <h1 class="title">
        Абоненты <span>t2</span> могут обменять 
        <!-- <br v-if="device === 'desktop'" /> -->
        минуты
        на&nbsp;билет в&nbsp;музей
      </h1>
      <div class="ticket__items">
        <h5
          class="ticket__item minutes"
          v-html="
            museum.textTicket
              ? museum.textTicket
              : 'Обменяйте минуты<br />на промокод'
          "
        />
        <h5
          class="ticket__item calendar"
          v-html="
            museum.textDate
              ? museum.textDate
              : 'Зарегистрируйтесь,<br />выбрав удобную дату'
          "
        />
        <h5
          class="ticket__item qr"
          v-html="
            museum.textTicket
              ? museum.textTicket
              : 'Предъявите билет<br />на входе в залы'
          "
        />
      </div>
      <!-- TODO: ссылка только на ИЗО -->
      <a
        href="https://ekt.tele2.ru/minutes-management/museum-ermitazh"
        target="_blank"
        class="btn btn-green"
      >
        Обменять минуты
      </a>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Ticket",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["museum"]),
  },
};
</script>

<style lang="scss" scoped>
#ticket {
  padding: 0 0 32px 0;
  @include onlyMobile {
    padding: 24px 0 12px 0;
  }
  .for {
    font-size: 20px;
    line-height: 140%;
    @include onlyMobile {
      font-size: 16px;
    }
  }
  h1.title {
    margin: 0 0 16px 0;
    @include tablet {
      margin: 0 0 24px 0;
    }
    @include onlyMobile {
      margin: 0 0 20px 0;
    }
    span {
      text-transform: none;
    }
  }
}
.ticket {
  position: relative;
  &__img {
    position: absolute;
    right: 0;
  }
  &__items {
    display: grid;
    grid-template-columns: repeat(3, min-content);
    grid-template-rows: 1fr;
    grid-column-gap: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
    // @media screen and (max-width: 1152px) {
    //   grid-template-columns: repeat(2, 1fr);
    //   grid-template-rows: repeat(2, 1fr);
    //   row-gap: 8px;
    //   grid-column-gap: 8px;
    //   margin-bottom: 8px;
    // }
    @media (max-width: 1160px) {
      grid-template-columns: repeat(3, auto);
    }
    @include tablet {
      // grid-template-columns: 1fr;
      row-gap: 8px;
      margin-bottom: 24px;
    }
    @include onlyMobile {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 8px;
      margin-bottom: 8px;
    }
  }
  &__item {
    font-family: var(--fontPrimary);
    width: 368px;
    height: 200px;
    background: #f5f5fa;
    border-radius: 32px;
    padding: 24px;
    background-repeat: no-repeat;
    background-position: 24px 24px;
    box-shadow: 0px 2px 16px 0px #0000000F;
    display: flex;
    align-items: flex-end;
    margin: 0;
    @media (max-width: 1160px) {
      max-width: 368px;
      width: auto;
    }
    @include onlyMobile {
      width: 272px;
      height: 153px;
      background-size: auto 48px;
      background-position: 12px 12px;
      padding: 12px;
      border-radius: 18px;
      max-width: 100%;
    }
    h5 {
      font-size: 18px;
      line-height: 140%;
      margin: 0 0 4px 0;
      @include onlyMobile {
        font-size: 16px;
      }
    }
    span {
      font-size: 16px;
      line-height: 140%;
      @include onlyMobile {
        font-size: 14px;
      }
    }
    &.minutes {
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      background-image: url("../assets/img/minutes-v2.svg");
      background-color: #fff;
      // @media screen and (max-width: 1152px) {
      //   grid-column: 1 / 2;
      //   grid-row: 1 / 2;
      //   width: 100%;
      // }
      @include onlyMobile {
        font-size: 16px;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        width: 100%;
        font-size: 12px;
        line-height: 16px;

      }
    }
    &.calendar {
      color: #FFFFFF;
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      background-image: url("../assets/img/calendar-v2.svg");
      background-color: #00BFFF;
      // @media screen and (max-width: 1152px) {
      //   grid-column: 2 / 3;
      //   grid-row: 1 / 2;
      //   width: 100%;
      // }
      @include onlyMobile {
        font-size: 16px;
        grid-column: 2 / 3;
        grid-row: 1 / 2;
        width: 100%;
        font-size: 12px;
        line-height: 16px;
      }
    }
    &.qr {
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
      background-color: #FF3495;
      background-image: url("../assets/img/ticket-v2.svg");
      // @media screen and (max-width: 1152px) {
      //   grid-column: 1 / 3;
      //   grid-row: 2 / 3;
      //   width: 100%;
      // }
      @include onlyMobile {
        font-size: 16px;
        grid-column: 1 / 3;
        grid-row: 2 / 3;
        width: 100%;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .btn {
    margin: 0;
    // @media screen and (max-width: 1152px) {
    //   width: 100%;
    // }
    @include onlyMobile {
      width: 100%;
      padding: 13px 35px;
    }
  }
}

.btn-black {
  border: none;
  background: #1f2229;
  color: white;
  border-radius: 24px;
  padding: 13px 33px;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  display: block;
  width: fit-content;
}

@media (max-width: 1023px) {
  .ticket {
    &__img {
      display: none;
    }
  }
}
</style>
