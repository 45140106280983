<template>
  <div v-if="classEl !== 'floor'" :class="['m-btn', classEl]">
    <button
      @click="$emit('select', btn)"
      v-for="(btn, i) of normilizeButtons"
      :key="i"
      :class="[
        'm-btn-item button',
        {
          [activeClass]:
            selected == btn ||
            selected == btn.name ||
            selected == btn.index + 1,
        },
      ]"
    >
      {{ btn.name ? btn.name : btn }}
    </button>
  </div>
  <div v-else :class="['m-btn', classEl]">
    <div v-for="(btns, ind) of normilizeButtons" :key="ind">
      <button
        @click="$emit('select', btn)"
        v-for="(btn, i) of btns"
        :key="i"
        :class="[
          'm-btn-item button',
          {
            [activeClass]:
              selected == btn ||
              selected == btn.name ||
              selected == btn.index + 1,
          },
        ]"
      >
        {{ btn.name ? btn.name : btn }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MultiButton",
  props: {
    buttons: {
      type: Array,
      require: true,
    },
    selected: {
      type: [String, Number],
      require: true,
    },
    classEl: {
      type: [String, Array],
      default: "",
    },
    activeClass: {
      type: String,
      default: "button--blue",
    },
  },
  computed: {
    normilizeButtons() {
      const find = this.buttons.find((el) => el.name == "1.1");
      if (find) {
        let map = this.buttons.map((el) => el.name[0]);
        map = [...new Set(map)];
        let newArray = [];
        for (let i = 0; i < map.length; i++) {
          let arr = this.buttons.filter((el) => el.name[0] == map[i]);
          newArray.push(arr);
        }
        return newArray;
      }
      return this.buttons;
    },
  },
};
</script>

<style lang="scss" scoped>
.m-btn {
  // border: 2px solid #1f2229;
  box-sizing: border-box;
  border-radius: 5px;
  width: fit-content;
  padding: 1px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  &.short {
    .m-btn {
      &-item {
        // padding-left: 5px;
        // padding-right: 5px;
        min-width: 28px;
        @include onlyMobile {
          min-width: 26px;
        }
      }
    }
  }
  &-item {
    font-size: 15px;
    font-weight: 400;
    line-height: 16px;
    font-family: var(--fontPrimary);
    padding: 16px 20px;
    // margin: 1px;
    border-radius: 16px;
    font-style: normal;
    cursor: pointer;
    background-color: var(--colorGray);
  }
  &.floor {
    display: flex;
    flex-direction: column;
  }
}
</style>
